import React, { useState } from 'react'
import axios from '../api/axios'
import ConfirmationModal from './ConfirmModal'
import styles from './CuratorPromotionButton.module.css'

const CuratorPromotionButton = ({ userUuid, setSelectedUsersRole, fetchData }) => {
  const [isLoading, setIsLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState(null)
  const [isModalOpen, setIsModalOpen] = useState(false)

  const handlePromote = async () => {
    setIsLoading(true)
    setErrorMessage(null)

    try {
      const response = await axios.put(`/api/admin/v1/user/${userUuid}/promote`)
      if (response.status === 200) {
        setSelectedUsersRole('куратор')
        fetchData()
      }
    } catch (error) {
      if (error.response) {
        setErrorMessage(error.response?.data?.message || 'Произошла ошибка')
      } else {
        setErrorMessage('Произошла ошибка при отправке запроса')
      }
    } finally {
      setIsLoading(false)
      setIsModalOpen(false)
    }
  }

  const handleOpenModal = () => setIsModalOpen(true)
  const handleCloseModal = () => setIsModalOpen(false)

  return (
    <div>
      <button onClick={handleOpenModal} disabled={isLoading} className={`${styles.promoteButton} ${isLoading ? styles.loadingButton : ''}`}>
        {isLoading ? 'Загрузка...' : 'Сделать куратором'}
      </button>

      {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}

      <ConfirmationModal
        isOpen={isModalOpen}
        onClose={handleCloseModal}
        onConfirm={handlePromote}
      >
        <p>Вы уверены, что хотите сделать пользователя куратором?</p>
      </ConfirmationModal>
    </div>
  )
}

export default CuratorPromotionButton
